import React, { useCallback, useEffect, useState } from "react";
import { Path, Router, To } from "react-router-dom";

function toPathname(to: To) {
  return typeof to === "string" ? to : to.pathname || "/";
}

function replaceHash(hash: string | null) {
  window.location.hash = hash || "/";
}

function isToPathObject(to: To): to is Path {
  return Boolean(typeof to !== "string" && ["search", "pathname", "hash"].every((prop) => prop in to));
}

export function ConnectRouter({ children }: { children: React.ReactNode }) {
  const [location, setLocation] = useState("/");
  const [urlBasename, setUrlBasename] = useState("/");

  const getAtlassianNavigator = useCallback((urlBasename: string) => {
    return {
      createHref: (to: To) => {
        const href = new URL(urlBasename);
        href.hash = `!${toPathname(to)}`;
        return href.toString();
      },
      go: AP.history.go,
      push: (to: To) => {
        AP.history.pushState(toPathname(to));
        if (isToPathObject(to)) {
          setLocation(to.pathname);
          replaceHash(to.hash);
        }
      },
      replace: (to: To) => AP.history.replaceState(toPathname(to)),
    };
  }, []);

  useEffect(() => {
    AP.history.getState("all", function ({ hash, href }) {
      setUrlBasename(href);
      setLocation(hash || "/");
      replaceHash(hash);
    });
    AP.history.popState((event) => {
      setLocation(event.newURL);
      replaceHash(event.hash);
    });
  }, []);
  return (
    AP && (
      <Router navigator={getAtlassianNavigator(urlBasename)} location={location}>
        {children}
      </Router>
    )
  );
}
