import { useCallback } from "react";

import { useApplicationContext } from "../providers/ApplicationContextProvider";
import { getIssuesByIds, getUsers, showFlag } from "../services/jira-api";
import { Game } from "../types";
import { groupByIssueKey } from "../utils";

export const COLUMNS = [
  "Name",
  "Issue Key",
  "Issue Name",
  "Issue Link",
  "Accepted Estimate",
  "Player Name",
  "Player Estimate",
  "Round Duration (seconds)",
];

export function useCSVExport(gameId: string, game: Game) {
  const { hostBaseUrl } = useApplicationContext();
  return useCallback(() => {
    void createCSV(hostBaseUrl, gameId, game);
  }, [game, gameId, hostBaseUrl]);
}

async function createCSV(hostBaseUrl: string, gameId: string, game: Game) {
  const gameName = game.configuration.name;
  const participants = Object.keys(game.participants);

  const data = [COLUMNS];
  const issueKeys = Object.keys(game.voting || {});
  try {
    const [issues, users] = await Promise.all([
      getIssuesByIds(issueKeys).then(groupByIssueKey),
      getUsers(participants),
    ]);
    Object.entries(game.voting || {}).forEach(([key, voting]) => {
      const { savedEstimate = "", votes, votingStart, votingEnd, votingDuration } = voting;
      if (!votes) return;

      const link = `${hostBaseUrl}/browse/${key}`;
      const summary = issues[key].fields.summary;
      // Also convert from ms to s
      let roundDuration = "";
      if (votingDuration) {
        roundDuration = (votingDuration / 1000).toFixed(3);
      } else if (votingEnd && votingStart) {
        roundDuration = ((votingEnd - votingStart) / 1000).toFixed(3);
      }

      participants.forEach((userAccountId) => {
        const vote = votes[userAccountId] || "";
        const displayName = users[userAccountId].displayName;
        data.push([gameName, key, summary, link, savedEstimate, displayName, vote, roundDuration]);
      });
    });
    generateCSVFile(data, `${gameId}.csv`);
  } catch (e) {
    showFlag("Error Creating CSV", "There was an issue generating the CSV file", "error");
    console.error(e);
  }
}

function generateCSVFile(data: (string | number)[][], filename: string = "data.csv"): void {
  const csvContent: string = data
    .map((row) =>
      row
        .map(String)
        .map((value) => `"${value.replace(/"/g, '""')}"`) //handling special characters in CSV files, specifically double quotes and commas
        .join(","),
    )
    .join("\n");

  const blob: Blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link: HTMLAnchorElement = document.createElement("a");
  const url: string = URL.createObjectURL(blob);
  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
