import { child, DatabaseReference, get, push, ref, remove, set, update } from "firebase/database";
import { useCallback, useMemo } from "react";
import { useDatabase, useDatabaseObjectData } from "reactfire";

import { ItemState } from "@appfire/poker-core";

import { useStartGameEvent } from "../analytics/hooks/events/useStartGameEvent";
import { useApplicationContext } from "../providers/ApplicationContextProvider";
import {
  Game,
  GameConfig,
  GameFormConfig,
  GameParticipant,
  Games,
  GameState,
  GlobalConfiguration,
  StartGameAnalyticsProps,
  Timer,
} from "../types";
import { getErrorMessage } from "../utils";
import { postGameNotification } from "./api";
import { showFlag } from "./jira-api";

function sanitizeFirebaseKey(key: string) {
  return encodeURIComponent(key).replace(/\./g, "%2E");
}

export const useClientRef = () => {
  const database = useDatabase();
  const { clientKey } = useApplicationContext();
  return useMemo(() => {
    const clientsRef = ref(database, "clients");
    return child(clientsRef, `${sanitizeFirebaseKey(clientKey)}`);
  }, [clientKey, database]);
};

export const useGamesMapRef = () => {
  const clientRef = useClientRef();
  return useMemo(() => child(clientRef, "games"), [clientRef]);
};

export const useGameItemRef = (gameId: string) => {
  const gamesMapRef = useGamesMapRef();

  return useMemo(() => child(gamesMapRef, `${gameId}`), [gameId, gamesMapRef]);
};

export const useGameTimerRef = (id: string) => {
  const gamesRef = useGameItemRef(id);

  return useMemo(() => child(gamesRef, "timer"), [gamesRef]);
};

export const useGameConfigurationRef = (id: string) => {
  const gamesRef = useGameItemRef(id);

  return useMemo(() => child(gamesRef, "configuration"), [gamesRef]);
};

export const getGamesForAdmin = async (gamesRef: DatabaseReference) => {
  const games = await get(gamesRef).then((snapshot) => (snapshot.val() as Games) || null);
  return Object.entries(games || {}).map(([key, value]) => ({ ...value, id: key }));
};

export const useGameItemRealTime = (gameId: string) => {
  const gameItemRef = useGameItemRef(gameId);

  return useDatabaseObjectData<Game | null>(gameItemRef);
};

export const useGetGameById = () => {
  const gamesRef = useGamesMapRef();
  return useCallback(
    async (gameId: string) => {
      const snapshot = await get(child(gamesRef, gameId));
      return snapshot.val() as Game;
    },
    [gamesRef],
  );
};

export const useGameCreate = () => {
  const gamesRef = useGamesMapRef();
  const { userAccountId } = useApplicationContext();
  const recordStartGameEvent = useStartGameEvent();

  const sendGameNotification = useCallback(async (gameId: string) => {
    try {
      await postGameNotification(gameId);
    } catch (error) {
      showFlag("Failed to send email notifications", getErrorMessage(error), "error");
    }
  }, []);

  return useCallback(
    async (
      gameConfig: GameFormConfig,
      analyticsProperties: Pick<StartGameAnalyticsProps, "advanced_expanded">,
      lastUserGame?: Game,
    ) => {
      const { participants, ...configuration } = gameConfig;
      const game: Game = {
        participants,
        configuration,
        creator: userAccountId,
        created: Date.now(),
        state: GameState.ACTIVE,
        updated: Date.now(),
        timer: {
          autoStartOnNextRound: false,
          selectedDuration: 60_000,
          customDurationValue: 120_000,
          ...(lastUserGame?.timer ?? {}),
          stopTimestamp: -1,
        },
      };

      try {
        const result = await push(gamesRef, game);

        if (result.key && configuration.sendEmails) {
          void sendGameNotification(result.key);
        }

        if (result.key) {
          recordStartGameEvent(game, { ...analyticsProperties, game_id: result.key });
        }

        return result;
      } catch (error) {
        showFlag("Failed to create game", getErrorMessage(error), "error");
      }
    },
    [gamesRef, recordStartGameEvent, sendGameNotification, userAccountId],
  );
};

export const useGameUpdate = (gameId: string) => {
  const gameItemRef = useGameItemRef(gameId);

  return useCallback(
    (newGameFormConfig: GameFormConfig) => {
      const { participants, ...configuration } = newGameFormConfig;
      const newGame = {
        participants,
        configuration,
        updated: Date.now(),
      };

      return update(gameItemRef, newGame);
    },
    [gameItemRef],
  );
};

export const useUpdateGameConfiguration = (gameId: string) => {
  const gameConfigurationRef = useGameConfigurationRef(gameId);

  return useCallback(
    (newConfig: Partial<GameConfig>) => {
      return update(gameConfigurationRef, newConfig);
    },
    [gameConfigurationRef],
  );
};

export function useGameDelete() {
  const gamesRef = useGamesMapRef();

  return useCallback(
    async (keys: string[]) => {
      const payload = keys.reduce<{ [key: string]: null }>((all, key) => {
        all[key] = null;
        return all;
      }, {});
      await update(gamesRef, payload);
    },
    [gamesRef],
  );
}

export const useRestartGame = () => {
  const gamesRef = useGamesMapRef();
  return useCallback(
    (gameId: string) => {
      return update(child(gamesRef, `${gameId}`), { state: GameState.ACTIVE, voting: null });
    },
    [gamesRef],
  );
};

export const useGameClone = () => {
  const gamesRef = useGamesMapRef();
  return useCallback(
    async (game: Game) => {
      return push(gamesRef, game);
    },
    [gamesRef],
  );
};

export function useGameSetActiveItemId(gameId: string) {
  const gameItemRef = useGameItemRef(gameId);

  return useCallback(
    (activeItemId: string | null) => {
      return update(gameItemRef, { activeItemId });
    },
    [gameItemRef],
  );
}

const useGlobalConfigurationRef = () => {
  const clientRef = useClientRef();
  return useMemo(() => child(clientRef, "configuration"), [clientRef]);
};

export const useGlobalConfiguration = () => {
  const configurationRef = useGlobalConfigurationRef();
  return useDatabaseObjectData<GlobalConfiguration | null>(configurationRef, {});
};

export function useUpdateGlobalConfiguration() {
  const configurationRef = useGlobalConfigurationRef();
  return useCallback(
    (configuration: Partial<GlobalConfiguration>) => {
      return update(configurationRef, configuration);
    },
    [configurationRef],
  );
}

export const useUpdateParticipant = (gameId: string) => {
  const gameRef = useGameItemRef(gameId);
  return useCallback(
    (accountId, participantData: Partial<GameParticipant>) => {
      const participantRef = child(gameRef, `participants/${accountId}`);
      return update(participantRef, participantData);
    },
    [gameRef],
  );
};

export const useRemoveParticipant = (gameId: string) => {
  const gameRef = useGameItemRef(gameId);
  return useCallback(
    (accountId: string) => {
      const participantRef = child(gameRef, `participants/${accountId}`);
      const adminRef = child(gameRef, `configuration/admins/${accountId}`);
      return Promise.all([remove(participantRef), remove(adminRef)]);
    },
    [gameRef],
  );
};

export function useItemVotingIsRevealedChange(gameId: string) {
  const gameRef = useGameItemRef(gameId);

  return useCallback(
    (issueKey: string, state: ItemState) => {
      const itemVotingRef = child(gameRef, `voting/${issueKey}/state`);
      return set(itemVotingRef, state);
    },
    [gameRef],
  );
}

export function useGameStateChange(gameId: string) {
  const gameRef = useGameItemRef(gameId);

  return useCallback(
    (state: GameState) => {
      const stateRef = child(gameRef, "state");
      return set(stateRef, state);
    },
    [gameRef],
  );
}

export function useSetFinalEstimate(gameId: string) {
  const gameRef = useGameItemRef(gameId);

  return useCallback(
    (issueKey: string, finalEstimate: string) => {
      const savedEstimateRef = child(gameRef, `voting/${issueKey}/savedEstimate`);
      return set(savedEstimateRef, finalEstimate);
    },
    [gameRef],
  );
}

export function useRemoveVotes(gameId: string) {
  const gameRef = useGameItemRef(gameId);

  return useCallback(
    (issueKey: string) => {
      const votesRef = child(gameRef, `voting/${issueKey}/votes`);
      return remove(votesRef);
    },
    [gameRef],
  );
}

export function useGameVoteUpdate(gameId: string) {
  const gameItemRef = useGameItemRef(gameId);
  const { userAccountId } = useApplicationContext();

  return useCallback(
    (voteValue: string | null, issueId: string) => {
      const votesRef = child(gameItemRef, `/voting/${issueId}/votes`);
      if (voteValue === null) {
        return remove(child(votesRef, userAccountId));
      } else {
        const voteToUpdate = { [userAccountId]: voteValue };
        return update(votesRef, voteToUpdate);
      }
    },
    [gameItemRef, userAccountId],
  );
}

export const useGetVotingStartRef = (gameId: string) => {
  const gameRef = useGameItemRef(gameId);

  return useCallback((issueKey: string) => child(gameRef, `voting/${issueKey}/votingStart`), [gameRef]);
};

export const useGetVotingEndRef = (gameId: string) => {
  const gameRef = useGameItemRef(gameId);

  return useCallback((issueKey: string) => child(gameRef, `voting/${issueKey}/votingEnd`), [gameRef]);
};

export const useGetVotingDurationRef = (gameId: string) => {
  const gameRef = useGameItemRef(gameId);

  return useCallback((issueKey: string) => child(gameRef, `voting/${issueKey}/votingDuration`), [gameRef]);
};

export function useGameVoteStartTimestampUpdate(gameId: string) {
  const getVotingStartRef = useGetVotingStartRef(gameId);

  return useCallback(
    (issueKey: string) => {
      const votingStartRef = getVotingStartRef(issueKey);
      return set(votingStartRef, Date.now());
    },
    [getVotingStartRef],
  );
}

export function useGameVoteEndTimestampUpdate(gameId: string) {
  const getVotingEndRef = useGetVotingEndRef(gameId);

  return useCallback(
    (issueKey: string) => {
      const votingEndRef = getVotingEndRef(issueKey);
      return set(votingEndRef, Date.now());
    },
    [getVotingEndRef],
  );
}

export function useGameVoteRoundDurationUpdate(gameId: string) {
  const getVotingDurationRef = useGetVotingDurationRef(gameId);

  return useCallback(
    (issueKey: string, duration: number) => {
      const votingDurationRef = getVotingDurationRef(issueKey);
      return set(votingDurationRef, duration);
    },
    [getVotingDurationRef],
  );
}

export const useResetRoundDurationTimeStamps = (gameId: string) => {
  const gameRef = useGameItemRef(gameId);

  return useCallback(
    (issueKey) => {
      const issueVotingRef = child(gameRef, `voting/${issueKey}`);
      return update(issueVotingRef, { votingEnd: null, votingStart: Date.now() });
    },
    [gameRef],
  );
};

export const useTimerUpdateStopTimestamp = (gameId: string) => {
  const gameTimerRef = useGameTimerRef(gameId);

  return useCallback(
    (newStopTimestamp: number) => update(gameTimerRef, { stopTimestamp: newStopTimestamp }),
    [gameTimerRef],
  );
};

export const useTimerUpdateSelectedDuration = (gameId: string) => {
  const gameTimerRef = useGameTimerRef(gameId);

  return useCallback(
    (newSelectedDuration: Timer["selectedDuration"]) => update(gameTimerRef, { selectedDuration: newSelectedDuration }),
    [gameTimerRef],
  );
};

export const useTimerUpdateCustomDurationValue = (gameId: string) => {
  const gameTimerRef = useGameTimerRef(gameId);

  return useCallback(
    (newCustomDurationValue: Timer["customDurationValue"]) =>
      update(gameTimerRef, { customDurationValue: newCustomDurationValue }),
    [gameTimerRef],
  );
};

export const useTimerUpdateAutoStartValue = (gameId: string) => {
  const gameTimerRef = useGameTimerRef(gameId);

  return useCallback(
    (newAutoStartOnNextRound: Timer["autoStartOnNextRound"]) =>
      update(gameTimerRef, { autoStartOnNextRound: newAutoStartOnNextRound }),
    [gameTimerRef],
  );
};
