import { GameState, ItemState } from "@appfire/poker-core";
import { OptionType } from "@atlaskit/select";

import { StartGameClickedProperties } from "../analytics/ampli";

export { GameState } from "@appfire/poker-core";

export type JSONValue = string | number | boolean | JSONObject | JSONArray;

export interface JSONObject<TValue extends JSONValue = JSONValue> {
  [x: string]: TValue;
}

export interface JSONArray<TValue extends JSONValue = JSONValue> extends Array<TValue> {}

export interface StringOptionType extends OptionType {
  value: string;
}

export interface ApplicationContext {
  firebaseToken: string;
  clientKey: string;
  userAccountId: string;
  hostBaseUrl: string;
  addonKey: string;
  isJiraAdmin: boolean;
  isProjectAdmin: boolean;
}

export interface JiraContext {
  jira?: {
    issue: {
      key: string;
    };
    project: {
      key: string;
      id: string;
    };
  };
  url: {
    displayUrl: string;
    displayUrlServicedeskHelpCenter: string;
  };
}

export interface JiraProject {
  name: string;
  key: string;
  id: number;
  avatarUrls: {
    [resolution: string]: string;
  };
  projectTypeKey: "software" | "service_desk" | "business";
  simplified: boolean;
}

export interface JiraStatus {
  name: string;
  untranslatedName: string;
  statusCategory: { key: "new" | "indeterminate" | "done" };
  id: number;
}

export type JiraFlagType = "info" | "success" | "warning" | "error";

export interface JiraUser {
  accountId: string;
  avatarUrls: {
    [key: string]: string;
  };
  displayName: string;
  active: boolean;
  accountType: string;
}

export interface JiraComponent {
  description: string;
  id: string;
  name: string;
}

export interface Games {
  [gameId: string]: Game;
}

export interface GameWithId extends Game {
  id: string;
}

export interface Users {
  [userId: string]: JiraUser;
}

export interface GameParticipant {
  isSpectator: boolean;
  accountId: string;
  instances?: Record<string, boolean>;
}

export interface GameConfig {
  name: string;
  cards: string[];
  private: boolean;
  estimationFieldId: string;
  coffeeCardsEnabled: boolean;
  admins: Record<string, boolean>;
  sendEmails: boolean;
  backlog?: string[];
  enableEstimationContext: boolean;
  limitEstimationContext: boolean;
  estimationContextCustomQuery?: string;
  backlogColumnIds?: string[];
  estimatedTransition: string | null;
  estimatedSprintId: string | null;
  estimatedLabel: string | null;
  skippedLabel: string | null;
  layoutFields?: string[];
  jql?: string;
  hideEstimatedIssues?: boolean;
}

export type GameFormConfig = GameConfig &
  Pick<Game, "participants"> & {
    backlogColumnIds: string[];
  };

export interface Voting {
  savedEstimate?: string;
  votes: Record<string, string>;
  state?: ItemState;
  votingStart?: number;
  votingEnd?: number;
  votingDuration?: number;
}

// In milliseconds
export interface Timer {
  autoStartOnNextRound: boolean;
  stopTimestamp: number;
  selectedDuration: number;
  customDurationValue: number;
}

export interface Game {
  creator: string;
  configuration: GameConfig;
  created: number;
  updated: number;
  state: GameState;
  participants: Record<string, GameParticipant>;
  activeItemId?: string;
  voting?: Record<string, Voting>;
  timer: Timer;
}

export enum JIRA_FIELD_SCHEMA_ITEMS {
  STRING = "string",
  JSON = "json",
  VERSION = "version",
  COMPONENT = "component",
}

export enum JIRA_FIELD_TYPE {
  PROJECT = "project",
  USER = "user",
  OPTION = "option",
  DATETIME = "datetime",
  TIME_TRACKING = "timetracking",
  ARRAY = "array",
  STRING = "string",
  NUMBER = "number",
  DATE = "date",
  COMMENTS = "comments-page",
}

export interface JiraFieldSchema {
  type?: JIRA_FIELD_TYPE;
  system: string;
  custom?: string;
  items?: JIRA_FIELD_SCHEMA_ITEMS;
}

export interface JiraField {
  id: string;
  name: string;
  clauseNames: string[];
  schema?: JiraFieldSchema;
}

export interface JiraGroup {
  html: string;
  name: string;
  groupId: string;
}

export interface JiraBoard {
  id: string;
  name: string;
}

export interface JiraSprint {
  id: string;
  name: string;
  originBoardId: string;
}

export interface GlobalConfiguration {
  limitedAccessEnabled: boolean;
  limitedGroups: string[];
  changeMobileUrlEnabled: boolean;
  mobileViewEnabled: boolean;
  estimationFieldId?: string;
  cards: string[];
}

export interface SessionlessVotes {
  [key: string]: string;
}

export interface SessionlessComments {
  [key: string]: string;
}

export interface SessionlessGame {
  isOver: boolean;
  votes?: SessionlessVotes;
  comments?: SessionlessComments;
}

export interface AddonInfo {
  version: string;
  license?: License;
}

export interface License {
  entitlementId?: string;
  supportEntitlementNumber?: string;
  evaluation: boolean;
  entitlementNumber?: string;
  active: boolean;
  type: "COMMERCIAL" | "COMMUNITY" | "ACADEMIC" | "DEVELOPER";
}

export interface GameFilters {
  search: string;
  gameState: string[];
  creator: string[];
}

export interface AbstractItem {
  key: string;
  id: string;
}

export enum MIME_TYPE {
  IMAGE = "image",
  VIDEO = "video",
  OTHER = "other",
}

export interface Attachment {
  id: number;
  created: Date;
  filename: string;
  content: string;
  mimeType?: string;
}

export interface Comment {
  id: string;
  author: JiraUser;
  body: string;
  created: string;
}

export interface JiraIssue extends AbstractItem {
  id: string;
  key: string;
  renderedFields: {
    [key: string]: unknown;
    description: string;
    comment?: {
      comments?: Comment[];
    };
  };
  self: string;
  fields: {
    attachment?: Attachment[];
    issuetype?: {
      iconUrl: string;
      id: string;
      name: string;
      subtask: boolean;
    };
    priority?: {
      iconUrl: string;
      id: string;
      name: string;
    } | null;
    summary: string;
    description: string;
    parent?: JiraIssue;
    project?: {
      name: string;
      simplified: boolean;
    };
    assignee: JiraUser | null;
    status?: {
      name: string;
      statusCategory: {
        key: "new" | "indeterminate" | "done";
      };
    };
    labels?: string[];
    components?: JiraComponent[];
    reporter: JiraUser | null;
    [key: string]: unknown;
  };
}

export interface EpicDetails {
  id: number;
  name: string;
  summary: string;
  color: {
    key: string;
  };
}

export interface JiraJqlValidation {
  queries: [
    {
      query: string;
      structure?: unknown;
      errors?: string[];
    },
  ];
}

export interface ProjectVisibilityProperty {
  isEnabled: boolean;
}

export interface ProjectConfiguration {
  inheritGlobalConfiguration?: boolean;
  estimationFieldId?: string;
  cards?: string[];
}

export interface JiraTransition {
  id: string;
  name: string;
}

export type StoredIssueData = JiraIssue & { additionalFetchedFields: string[] };

export type StartGameAnalyticsProps = Pick<StartGameClickedProperties, "advanced_expanded" | "game_id">;

export type GameConfigAmplitudeProps = Omit<StartGameClickedProperties, "game_id" | "advanced_expanded">;

export interface IssueTransition {
  id: string;
  name: string;
  to: {
    id: string;
    name: string;
  };
}
