import React from "react";
import styled from "styled-components";

import { Checkbox } from "@atlaskit/checkbox";
import { Box } from "@fuegokit/react";

import { GameFormConfig } from "../../types";
import { CardDeckSelector } from "../shared/CardDeck/CardDeckSelector";
import { EstimationFieldSelect } from "../shared/EstimationFieldSelect";
import { InfoTooltip } from "../shared/InfoTooltip";
import { ParticipantsSelectors } from "./ParticipantsSelectors";

interface BasicGameConfigFormProps {
  isNewGame?: boolean;
  gameConfig: GameFormConfig;
  onConfigChange: (toUpdate: Partial<GameFormConfig>) => void;
  gameCreatorId: string;
}

const CheckboxContainer = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--ds-text-subtle, #6b778c);
`;

export function BasicGameConfigForm({
  isNewGame,
  gameConfig,
  onConfigChange,
  gameCreatorId,
}: Readonly<BasicGameConfigFormProps>) {
  return (
    <Box width="100%" marginTop="8px" paddingBottom="16px">
      <EstimationFieldSelect
        selectedEstimationFieldId={gameConfig.estimationFieldId}
        fixIncorrectField
        onEstimationFieldIdSelect={(estimationFieldId) => onConfigChange({ estimationFieldId })}
      />
      <CardDeckSelector
        onUpdate={onConfigChange}
        currentCards={gameConfig.cards}
        tooltip="Select one of the popular card decks or customize your own one."
      />
      <CheckboxContainer>
        <Checkbox
          label="Add “?” and “Coffee break” cards to the deck"
          isChecked={gameConfig.coffeeCardsEnabled}
          onChange={(e) => onConfigChange({ coffeeCardsEnabled: e.target.checked })}
        />
        <InfoTooltip message="? card is for estimates participants are not sure about; coffee - if participants need a break" />
      </CheckboxContainer>
      <ParticipantsSelectors gameConfig={gameConfig} onConfigChange={onConfigChange} gameCreatorId={gameCreatorId} />
      {isNewGame ? (
        <CheckboxContainer>
          <Checkbox
            label="Invite participants via email"
            isChecked={gameConfig.sendEmails}
            onChange={(e) => onConfigChange({ sendEmails: e.target.checked })}
          />
        </CheckboxContainer>
      ) : null}
    </Box>
  );
}
